import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import style from './style.module.scss'
import  JP from './Data/jp'
import RangerCMYK from './Component/RangerCMYK'
import RangerRGB from './Component/RangerRGB'

import MainRGB from './Component/MainRGB';
import MainCMYK from './Component/MainCMYK';
import classNames from 'classnames'
import Color from 'color'

function App() {
    const [current, updateCurrent] = useState(JP[0])
    const [before, updateBefore] = useState(JP[0])
    const [fade, updateFade] = useState(false)
    const [active, updateActive] = useState(0)
    const [dialog, updateDialog] = useState(false)
    const [dialogOuter, updateDialogOuter] = useState(false)
    return (
        <div className={style.container}  style={{backgroundColor: '#' + current.color}}>
        <div className={style.wrapperOuter} >
            <div className={classNames(style.wrapper, dialogOuter && style.fade)}>
            {
                JP.map((item, index) => {
                    return <div className={classNames(style.item, index === active && style.active)} onClick={() =>{
                            updateBefore(current)
                            updateFade(true)
                            updateActive(index)
                            setTimeout(() => {
                                updateFade(false)
                                updateCurrent(item)
                                document.querySelector('body').style.backgroundColor = '#' + item.color
                            }, 1000)
                        }}>
                        <div className={style.btn} style={{ backgroundColor: '#' + item.color }}></div>
                        <div className={style.cmykWrapper}>
                            
                            <RangerCMYK value ={item.color} />
                            <div className={style.title}>{item.cname}</div>
                        </div>
                        <div className={style.text}>
                            <div className={style.desc}>{item.name.toUpperCase()}</div>
                            <div className={style.rgb}>
                                <RangerRGB  value={item.color} />
                            </div>
                            <div className={style.desc}>{item.color}</div>
                        </div>
                    </div>
                })
            }
            </div>
            </div>
            <div className= {classNames(style.current, fade && style.fade,  dialogOuter && style.fade)} onClick={(e) => {
                console.log(Color('#' + current.color))
                updateDialogOuter(true)
                setTimeout(() => {
                    updateDialog(true)
                }, 1000)
                
            }}>
                <div className ={style.currentTitle}>{current.cname}</div>
                <div className={style.currentDesc}>{current.name.toUpperCase()}</div>
            </div>
            <div className = {classNames(style.currentCount, dialogOuter && style.fade)}>
                <MainRGB  current={current.color} before ={before.color} />
            </div>
            <div 
                className={classNames(style.dialog, dialog && style.show)}
                onClick={() => {
            }}>
                <div className ={style.dialogTitle}>{current.cname}</div>
                <div className ={style.dialogSubTitle}>#{current.color}</div>
                <div className={style.dialogDesc}>{
                   'RGB: ' + Color('#' + current.color).rgb().color.join(',')
                }</div>
                <div className={style.dialogDesc}>{
                   'CMYK: ' + Color('#' + current.color).cmyk().color.map((item) => item.toFixed(0)).join(',')
                }</div>
                <div className={style.dialogDesc}>{
                   'HSL: ' + Color('#' + current.color).hsl().color.map((item) => item.toFixed(0)).join(',')
                }</div>
                <div className={style.dialogDesc}>{
                   'LAB: ' + Color('#' + current.color).lab().color.map((item) => item.toFixed(0)).join(',')
                }</div>

                <div className={style.dialogDesc}>{
                   'HSV: ' + Color('#' + current.color).hsv().color.map((item) => item.toFixed(0)).join(',')
                }</div>
                <div className={style.dialogDesc}>{
                   'HWB: ' + Color('#' + current.color).hwb().color.map((item) => item.toFixed(0)).join(',')
                }</div>
                <div className={style.dialogDesc}>{
                   'XYZ: ' + Color('#' + current.color).xyz().color.map((item) => item.toFixed(0)).join(',')
                }</div>
                <div className={style.dialogClose}>
                    <img src="https://cdn.hicous.com/pic/k38vs.svg" width="60" onClick={() =>{
                         updateDialog(false)
                        setTimeout(() => {
                            updateDialogOuter(false)
                        }, 1000)
                    }}/>
                </div>
                
            </div>
        </div>
    );
}

export default App;
