import React from 'react';
import Color from 'color'
import style from './MainRGB.module.scss'
import Odometer from 'react-odometerjs';

const formatColor = (c) =>  Color('#' + c).rgb().color
const MainRGB= (props) => {
    const r =  formatColor(props.current)[0]
    const g =  formatColor(props.current)[1]
    const b =  formatColor(props.current)[2]
    const _r =  formatColor(props.before)[0]
    const _g =  formatColor(props.before)[1]
    const _b =  formatColor(props.before)[2]
    return <div className={style.wrapper}>
         
        <div className={style.item}>
            <div className={style.name}>R</div>
            <Odometer value={r} format="" />
        </div>
        <div className={style.item}>
            <div className={style.name}>G</div>
            <Odometer value={g} format="" />
        </div>
        <div className={style.item}>
            <div className={style.name}>B</div>
            <Odometer value={b} format="" />
        </div>             

    </div>
}
export default MainRGB