import React from 'react';
import Color from 'color'
const ratio = 760 / 100
const formatColor = (c) =>  Color('#' + c).cmyk().color
const Circle = (props) =>{
    const c = formatColor(props.value)[0] * ratio
    const m = formatColor(props.value)[1] * ratio
    const y = formatColor(props.value)[2] * ratio
    const k = formatColor(props.value)[3] * ratio
    return <svg width="20" height="80" viewBox="0 0 440 1760">
        <circle 
            cx="220" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            opacity="0.2"
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`760 760`} 
        />
        <circle 
            cx="220" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`${c} 760`} 
        />

        <circle 
            cx="-220" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            opacity="0.2"
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`760 760`} 
        />
        <circle 
            cx="-220" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`${m} 760`} 
        />
        <circle 
            cx="-660" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            opacity="0.2"
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`760 760`} 
        />
        <circle 
            cx="-660" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`${y} 760`} 
        />

        <circle 
            cx="-1100" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            opacity="0.2"
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`760 760`} 
        />
        <circle 
            cx="-1100" 
            cy="220" 
            r="120" 
            strokeWidth="100" 
            stroke="#fff" 
            fill="none" 
            transform="matrix(0,-1,1,0,0,440)" 
            strokeDasharray={`${k} 760`} 
        />
    </svg>
}
export default Circle;