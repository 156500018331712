import React from 'react';
import Color from 'color'
const ratio = 95 / 255
const formatColor = (c) =>  Color('#' + c).rgb().color
const RangerBar = (props) => {
    const r =  formatColor(props.value)[0] * ratio
    const g =  formatColor(props.value)[1] * ratio
    const b =  formatColor(props.value)[2] * ratio
    return <svg width="5" height="95" viewBox="0 0 5 95">
        <rect x="0" y="0" width="1" height="95" fill="#fff" fillOpacity="0.2" />
        <rect x="0" y="0" width="1" height={r} fill="#fff" />
        <rect x="2" y="0" width="1" height="95" fill="#fff" fillOpacity="0.2" />
        <rect x="2" y="0" width="1" height={g} fill="#fff" />
        <rect x="4" y="0" width="1" height="95" fill="#fff" fillOpacity="0.2" />
        <rect x="4" y="0" width="1" height={b} fill="#fff" />
    </svg>
}
export default RangerBar;